
input.navbar-search {
  background-color: #212529;
  padding-left: 7px;
  margin-left: 60px;
  flex-flow: column;
}

input.navbar-search:focus {
  background-color: #212529;
  color: aliceblue;
}

.border-box {
  box-sizing: border-box;
  min-width: 100% !important;
}

a#nav-dropdown-account-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.modeswitch-item .btn-modeswitch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateX(-14%);
  transform: translateX(-14%);
  height: 35px;
  width: 35px;
  border-radius: 0.4rem;
  -webkit-transition: all 1s;
  transition: all 1s;
}

