
.col-1-5 {
    width: 12.5%;
    flex: 0 0 auto;
}

.col-2-5 {
    width: 20.83333%;
    flex: 0 0 auto;
}

.col-3-5 {
    width: 29.16667%;
    flex: 0 0 auto;
}

.col-4-5 {
    width: 37.5%;
}

.col-5-5 {
    width: 45.83333%;
    flex: 0 0 auto;
}

.col-7-5 {
    width: 62.5%;
    flex: 0 0 auto
}

.col-8-5 {
    width: 70.83333%;
    flex: 0 0 auto
}

.col-9-5 {
    width: 79.16667%;
    flex: 0 0 auto;
}

.col-10-5 {
    width: 87.5%;
    flex: 0 0 auto;
}

.col-11-5 {
    width: 95.8333%;
    flex: 0 0 auto;
}


/*col-md-*/
@media (min-width: 768px) {
    .col-md-1-5 {
        width: 20.83333% !important;
        flex: 0 0 auto !important;
    }

    .col-md-2-35 {
        flex: 0 0 auto !important;
        width: 19.5833333% !important;
    }

    .col-md-2-5 {
        width: 20.83333% !important;
        flex: 0 0 auto !important;
    }

    .col-md-7-5 {
        width: 62.5% !important;
        flex: 0 0 auto !important;
    }

    .col-md-9-65 {
        flex: 0 0 auto !important;
        width: 80.4166666% !important;
    }

    .col-md-10-5 {
        width: 87.5% !important;
        flex: 0 0 auto !important;
    }


}

/*col-l-*/
@media (min-width: 992px) {
    .col-lg-1-75 {
        flex: 0 0 auto !important;
        width: 14.58333333% !important;
    }

    .col-lg-10-25 {
        flex: 0 0 auto !important;
        width: 85.4166666% !important;
    }
}

/*col-xl-*/
@media (min-width: 1200px) {
    .col-xl-1-5 {
        flex: 0 0 auto !important;
        width: 12.5% !important;
    }

    .col-xl-10-5 {
        flex: 0 0 auto !important;
        width: 87.5% !important;
    }
}

/*col-xxl-*/
@media (min-width: 1400px) {
    .col-xxl-1-5 {
        flex: 0 0 auto !important;
        width: 12.5% !important;
    }

    .col-xxl-10-5 {
        flex: 0 0 auto !important;
        width: 87.5% !important;
    }

    .col-xxl-1-1 {
        flex: 0 0 auto !important;
        width: 11% !important;
    }

    .col-xxl-10-9 {
        flex: 0 0 auto !important;
        width: 89% !important;
    }
}


.col-left {
    flex: 0 0 auto !important;
    width: 12.5% !important;
    margin-right: 4px !important;
}

.footer {
    /*background-color: #F0F2F6 !important;*/
}

.col-right {
    padding-top: 8px;
    min-height: 100%;
}

.row-middle {
    /*  padding-top: 8px;*/
    min-height: 100%;
}

.col-right {
    width: 200px !important;
    height: 100%;
    min-height: 100%;
}


.sticky-top-under-header {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 1020;
}


/*navbar*/


/*.navbar-search:focus {
    background-color: white !important;
}*/

