
.search-form {
    position: absolute;
    width: 100%;
    max-width: 400px;
}

.search-form-control {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 400px;
}

.search-results {
    position: relative;
    width: 100%;
    overflow:auto;
    overflow-x: hidden;
    z-index: 1000;
    max-height: 45vh;
    #overflow: auto;
}

/*.search-results-item:last-child {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    color:red;
}*/

.borders-radius-0-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}


.borders-radius-0-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}