.profile-right-menu {
    width: 130px;
    height:100%;
    maxHeight:100%;
    display: inline-block;
}

.profile-right-menu > ul >li {
   display: inline-block;
    padding-right: 5px;
}
.nav > li > a {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.nav > li > a:hover {
    /*background-color: #e4e9f2;*/
    /*background-color: #e9ecef;*/
}

.nav > li > a > svg {
    font-size: 18px;
    margin-right: 7px;
}
