@import "/node_modules/bootstrap/scss/bootstrap";


.App {
  text-align: center;
  /*background-color: $primary;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main-container {
  #position: relative !important;
  padding-top: 10px;
  min-height: calc(100vh - 55px);
  #height: 100%;
  #margin-bottom: 90px;
  display: flex;
  flex-direction: column;
}

.min-vh-row {
  min-height: calc(100vh - 134px);
}


.text {
  font-size: 14px;
}

.vh-50 {
  height: 50vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

@media (max-width: 480px) {

  /*  .text {
      font-size: 16px;
    }
    html {
      overscroll-behavior: none;
    }*/

}


/*html {
  overscroll-behavior: contain;
}*/


.main-banner-background {
  background-color: #ffffff;
  opacity: 0.75;
  border: 0;
  padding: 5px;
  width: 40vw;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}


