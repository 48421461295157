footer {
    position: absolute !important;
    overflow:  visible;
    #bottom: 0 !important;
    align-self: center;
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 10px;
    /* Set the fixed height of the footer here */
    height: 90px;
    background-color: #EFF2F7;

}
