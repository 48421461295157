/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500&family=Roboto:ital,wght@0,400;0,500;0,700;1,300&family=Work+Sans:wght@300;400&display=swap');
body {
    margin: 0;
    padding: 0;
    background: #1f4d8a;
    /*font-family: Open-Sans, Helvetica, Sans-Serif;*/
    font-family: 'Work Sans', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: auto;
}
#root{
    margin:0 auto;
    #font-size: calc(0.5em + 0.5vw);
    font-size: 14px;
}

a{
    text-decoration: none;
}


/*--- bootstrap text ---*/

small, .x-small {
    font-size: 0.775em;
}

small, .xx-small {
    font-size: 0.675em;
}


/*--- bootstrap buttons ---*/
.btn-xs, .btn-group-sm > .btn {
    --bs-btn-padding-y: 0.2rem;
    --bs-btn-padding-x: 0.33rem;
    --bs-btn-font-size: 0.825rem;
    --bs-btn-border-radius: var(--bs-border-radius-sm);
}