.img-sm {
    width: 46px;
    height: 46px;
}

.comments-panel {
    box-shadow: 0 2px 0 rgba(0,0,0,0.075);
    border-radius: 0;
    border: 0;
    margin-bottom: 15px;
}


.comments-panel-body {
    padding: 1.4em 1em;
}



.comment-icon {
    color: rgba(49, 49, 51, 0.98);
    padding: 7px 10px;
    border-radius: 18px;
    text-decoration: none;

}

.comment-icon:hover {
    background-color: rgba(232, 235, 241, 0.98);
}

