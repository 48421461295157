
.price-wrapper {
    position: relative;
}

.price-background {
    position: absolute;
    width: fit-content;
    padding: 0 4px;
    border-radius: 4px;
}

.price-value {
    position: relative;
    padding: 0 4px;
}


/*.price-increase-enter-active .price-background,
.price-decrease-enter-active .price-background {
    background-position: 2px 0, 2px 0, 0 0, 0 0;
    background-size: 2px 2px;
    background-repeat: repeat;
    animation: fadeOut 1s;
}*/

.price-increase-enter-active {
    background:url("/public/backgrounds/green-squares-pattern.png");
    #padding: 0.25rem;
    border-radius: 0.3em;
    animation: fadeOut 1s;
}

.price-decrease-enter-active  {
    background:url("/public/backgrounds/red-triangle-pattern.png");
    #padding: 0.25rem;
    border-radius: 0.3em;
    animation: fadeOut 1s;
}

/*.bg-pattern {
    background:url("/public/backgrounds/green-squares-pattern.png");
    padding: 0.2em;
    border-radius: 0.3em;
}*/

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}


/*.wrapper {
    background-color: red;
    background-size: cover;
    padding: 5px;
    display: inline-block;
    opacity: 0.9;
    border: 2px solid red;
    border-radius: 4px;
}*/

/*.price-increase {
    color: #228a3a
}

.price-decrease {
    color: #db2929;
}*/

.edit-badge {
    display: inline-block;
}
.profile-info-tab:hover + .edit-badge {
    display: block;
    color: red;

}





