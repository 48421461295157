@import "/node_modules/bootstrap/scss/bootstrap";


.sidebar > li > a {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: $body-bg-dark;
}

.sidebar > li > a:hover {
  background-color: $dark-border-subtle;
  color: $light;
}

.sidebar > li > a > svg {
  font-size: 18px;
  margin-right: 7px;
}


@include color-mode(dark) {
  .sidebar > li > a {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $dark-border-subtle;
  }

  .sidebar > li > a:hover {
    background-color: $primary-text-emphasis-dark;
    color: $dark;
  }


  .dropdown-divider {
    color: red;
    background-color: red;
  }


}
