/* Reduce card vertical spacing */
.position-preview-card {
    padding: 8px 16px; /* Less vertical padding */
    margin-bottom: 8px; /* Less vertical margin */
    transition: background-color 0.2s ease-in-out; /* Smooth hover effect */
}

/* Highlight position on hover */
.position-preview-card:hover {
    background-color: rgba(0, 123, 255, 0.1); /* Light blue highlight */
    cursor: pointer;
}

/* Reduce padding inside the card body */
.position-preview-body {
    padding: 8px;
}
